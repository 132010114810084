<template>
  <action-dispatcher
    action="user/getThirdProfile"
    :parameters="$route.params.slug"
    @success="onSuccess"
  >
    <div
      v-if="profile && profile.id"
      class="views-professional-profile pb-3 pb-lg-5"
    >
      <div
        v-if="profile.cover"
        class="cover-img"
        :style="`background: url(${profile.cover})`"
      >
        <div class="d-flex pl-4">
          <button
            v-if="isLogged"
            class="btn btn-back p-0 m-0"
            @click="$router.go(-1)"
          >
            <i class="icon-return text-light" />
          </button>
        </div>
      </div>
      <div v-else class="cover-img fallback">
        <div class="d-flex pl-4 pt-3">
          <button class="btn btn-back p-0 m-0" @click="$router.go(-1)">
            <i class="icon-return text-light" />
          </button>
        </div>
      </div>
      <professional-profile
        class="mt-lg-5 margin-negative"
        :profile="profile"
        @update-profile-data="updateProfileData"
      />
    </div>
    <div v-else class="text-center views-professional-profile pb-3 pb-lg-5">
      Perfil profissional não encontrado
    </div>
  </action-dispatcher>
</template>
<script>
import ProfessionalProfile from "@/components/professional/Profile";
export default {
  components: { ProfessionalProfile },
  data() {
    return {
      profile: null,
      active_tab: 0
    };
  },
  computed: {
    isLogged() {
      return this.$store.state.user.logged;
    }
  },
  mounted() {
    this.$emit("cancelPadding", true);
    document.onkeydown = evt => {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        this.$router.go(-1);
      }
    };
  },
  methods: {
    updateProfileData() {
      this.$store
        .dispatch("user/getThirdProfile", this.$route.params.slug)
        .then(data => {
          this.profile = data;
        });
    },
    onSuccess(data) {
      this.profile = data;
    }
  }
};
</script>
<style lang="scss" scoped>
.views-professional-profile {
  background: #e5e5e5;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .btn-back {
    i {
      font-size: 28px;
    }
  }
  .margin-negative {
    @media screen and (max-width: 768px) {
      margin-top: -60px;
    }
  }
  .cover-img {
    @media screen and (max-width: 768px) {
      height: 200px;
    }
    height: 250px;
    //padding: 30%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.fallback {
      @media screen and (max-width: 991.97px) {
        height: 100px;
      }

      .btn-back {
        mix-blend-mode: difference;
      }
    }
  }
}
</style>
